import React from 'react';
import styled, { css } from 'styled-components';

import Layout from 'components/Order/Layout';
import MemberInfo from 'components/Member/MemberInfo';
import Auth from 'components/Auth';
import { md } from 'utils/breakpoints';
import TitleName from 'components/UI/Header/TitleName';

const Container = styled.div`
  @media (max-width: ${md}) {
    padding: 0px 0px 130px;
    background-color: #fff;
  }
`;

const TitleBar = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${md}) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 108px;
    background-color: #ffffff;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    z-index: 1000;
    padding: 24px 15px;
  }
`;

const Title = styled.div`
  @media (max-width: ${md}) {
    display: none;
  }
`;

const BtnGroup = styled.div`
  display: flex;
  gap: 0 8px;

  @media (max-width: ${md}) {
    flex: 1;
  }
`;

const Btn = styled.div`
  width: 114px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #3b3516;
  border-radius: 30px;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  cursor: pointer;

  ${(props) =>
    props.primary &&
    css`
      background-color: #eafeff;
      border: 1px solid #5fd2da;
    `}
`;

const DeleteBtn = styled(Btn)`
  @media (max-width: ${md}) {
    flex: 1;
    height: 60px;
    background-color: #5fd2da;
    border: 1px solid #5fd2da;
    border-radius: 40px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
  }
`;

const MemberDelete = (props) => {
  const pageTitle = '申請刪除帳號';

  return (
    <Layout {...props}>
      <TitleName />
      <Auth />
      <Container>
        <TitleBar>
          <Title>{pageTitle}</Title>
          <BtnGroup>
            <DeleteBtn primary>確定刪除</DeleteBtn>
          </BtnGroup>
        </TitleBar>
        <MemberInfo />
      </Container>
    </Layout>
  );
};

export default MemberDelete;
